import { FC, ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';

import { getRandomNumber } from 'utils/getRandomNumber';

import { BSBadge } from '../Badge';

interface PlainTableProps {
    heading?: string;
    headingActionElement?: ReactElement;
    rows: Array<{
        value: string;
        status?: string;
        actionElements?: ReactElement[];
        actionElementsStart?: ReactElement[];
    }>;
    loading?: boolean;
    noRecordsMessage?: string;
}

export const SimpleStatusTable: FC<PlainTableProps> = ({
    heading,
    headingActionElement,
    rows,
    loading,
    noRecordsMessage,
}) => {
    return (
        <div className="c-panel">
            {(heading || headingActionElement) && (
                <div className="c-panel-head">
                    {heading && <div className="c-panel-title fw-bold">{heading}</div>}
                    {headingActionElement && <>{headingActionElement}</>}
                </div>
            )}
            <div className="c-panel-body">
                {loading && (
                    <div className="internal-loader">
                        <Spinner
                            as="span"
                            variant="primary"
                            className="m-2"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                        />
                    </div>
                )}
                {!loading &&
                    rows.map((row) => (
                        <div className="c-panel-col" key={`${row.value}-${getRandomNumber()}`}>
                            {row.actionElementsStart &&
                                row.actionElementsStart.map((actionElement: ReactElement) => (
                                    <div key={`${getRandomNumber()}`}>{actionElement}</div>
                                ))}
                            <div className="c-panel-title w-60 pe-2">{row.value}</div>
                            <BSBadge fill badgeText={row.status as string} />
                            <div className="d-flex ms-auto align-items-center">
                                {row.actionElements &&
                                    row.actionElements.map((actionElement: ReactElement) => (
                                        <div key={`${getRandomNumber()}`}>{actionElement}</div>
                                    ))}
                            </div>
                        </div>
                    ))}
                {!loading && !rows.length && noRecordsMessage && (
                    <div className="c-panel-col">
                        <div className="c-panel-title w-60 pe-2">{noRecordsMessage}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
