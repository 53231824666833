import { AppConstant, PermissionConstants } from 'common';
import {
    useAppSelector,
    useIsCoeusUser,
    useTranslate,
    useIsAbleTo,
    useIsPathNameMatching,
    useIsCustomerUser,
    useIsTPAUser,
} from 'hooks';
import { getFeatureFlags } from 'utils/environment';

export interface NavBarItem {
    link: string;
    name: string;
    count?: number;
    icon?: string;
    renderAsAnchor?: boolean;
    matchExact?: boolean;
    condition: () => boolean;
    vbcItem?: boolean;
}

export const useNavBarData = (): NavBarItem[] => {
    const { t } = useTranslate();
    const isCoeusUser = useIsCoeusUser();
    const isCustomerUser = useIsCustomerUser();
    const isTPAUser = useIsTPAUser();
    const { isPathMatching } = useIsPathNameMatching();
    const { pendingReviewCount, isDelegate, isContractingEntity } = useAppSelector(
        (state) => state.vbc
    );
    const isVBCAgreementRoute = isPathMatching(AppConstant.ROUTE_PATHS.VBC_AGREEMENT);
    const isVBCRoute = isPathMatching(AppConstant.ROUTE_PATHS.APPS_VBC) && !isVBCAgreementRoute;
    const { isAbleTo } = useIsAbleTo();
    const canViewCustomers = isAbleTo(PermissionConstants.MANUFACTURER_VIEW);
    const canViewAllOrgs = isAbleTo(PermissionConstants.VIEW_ALL_ORGANIZATIONS);
    const canCreateAndEditNotifications = isAbleTo([
        PermissionConstants.INFORMATIONAL_NOTIFICATION_EDIT,
        PermissionConstants.INFORMATIONAL_NOTIFICATION_CREATE,
    ]);

    // Platform Routes
    const coeusOrgRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.ORGANIZATIONS,
        name: 'COEUS organization',
        icon: 'CoeusIcon',
        condition: () => {
            return isCoeusUser;
        },
    };
    const myOrgRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.ORGANIZATIONS,
        name: t.MY_ORGANIZATION,
        icon: 'OrganizationIcon',
        condition: () => {
            return !isCoeusUser;
        },
    };
    const allOrgsRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.ORGANIZATIONS_ALL,
        name: t.ORGANIZATION_ACCOUNTS,
        icon: 'OrganizationIcon',
        condition: () => {
            return canViewAllOrgs;
        },
    };
    const customersRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.CUSTOMERS,
        name: t.MANUFACTURERS,
        icon: 'ParticipantIcon',
        condition: () => {
            return canViewCustomers;
        },
    };
    const tpaPortalsRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.TPA_PORTAL,
        name: t.TPA_PORTAL,
        icon: 'ManageUsersIcon',
        condition: () => {
            return isTPAUser || isCoeusUser;
        },
    };

    const adminPortalsRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.ADMIN_PORTAL,
        name: t.ADMIN_PORTAL,
        icon: 'ManageUsersIcon',
        condition: () => {
            return isCoeusUser;
        },
    };

    const notificationsRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.NOTIFICATIONS,
        name: t.NOTIFICATIONS,
        icon: 'NotificationIcon',
        condition: () => {
            return isCoeusUser && canCreateAndEditNotifications;
        },
    };

    // VBC Routes
    const vbcDashboardRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.VBC_DASHBOARD,
        name: t.DASHBOARD,
        icon: 'DashboardIcon',
        vbcItem: true,
        condition: () => {
            return (
                isAbleTo(PermissionConstants.DASHBOARD_ACCESS) &&
                isVBCRoute &&
                !isContractingEntity &&
                !isDelegate
            );
        },
    };
    const vbcParticipantsRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.VBC_PAYERS,
        name: t.PAYERS,
        icon: 'ManageUsersIcon',
        vbcItem: true,
        condition: () => {
            return (
                isAbleTo(PermissionConstants.VBC_LIST_PAYERS) &&
                isVBCRoute &&
                !isContractingEntity &&
                !isDelegate
            );
        },
    };
    const vbcCoverageVerificationRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.VBC_CLINICAL_POLICY_VERIFICATION,
        name: t.COVERAGE_VERIFICATION,
        count: pendingReviewCount,
        icon: 'PolicyVerificationIcon',
        vbcItem: true,
        condition: () => {
            return (
                isAbleTo(PermissionConstants.PRIMARY_POLICIES_VIEW_PRIMARY_POLICY) &&
                isVBCRoute &&
                !isDelegate
            );
        },
    };
    const vbcHealthPlansRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.VBC_HEALTH_PLANS,
        name: t.HEALTH_PLANS,
        icon: 'HealthPlanIcon',
        vbcItem: true,
        condition: () => {
            return isAbleTo(PermissionConstants.HEALTH_PLAN_VIEW) && isVBCRoute;
        },
    };
    const vbcSubmissionsRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.VBC_SUBMISSIONS,
        name: 'Submissions',
        icon: 'SubmissionIcon',
        vbcItem: true,
        condition: () =>
            isVBCRoute &&
            isAbleTo([
                PermissionConstants.CLAIM_VIEW_CLAIM_DETAILS,
                PermissionConstants.DATA_INTAKE_ACCESS,
                PermissionConstants.CLAIM_DRAFT_CLAIMS_ACCESS,
                PermissionConstants.CLAIM_STATUS_ACCESS,
            ]),
    };
    const vbcReportsRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.VBC_REPORTS,
        name: 'Reports',
        icon: 'ReportIcon',
        vbcItem: true,
        condition: () =>
            isVBCRoute &&
            isAbleTo([
                PermissionConstants.OUTCOMES_REPORTS_VIEW,
                PermissionConstants.STATUS_REPORTS_ACCESS,
                PermissionConstants.MONITORING_REPORT_GENERATE_MONITORING_REPORT,
                PermissionConstants.CUSTOM_REPORTS,
            ]),
        matchExact: false,
    };

    const vbcMarketplaceRoute: NavBarItem = {
        link: AppConstant.ROUTE_PATHS.VBC_MARKETPLACE,
        name: 'Marketplace',
        icon: 'MarketplaceIcon',
        condition: () => {
            return (
                getFeatureFlags(AppConstant.FEATURE_FLAGS.VBC_MARKETPLACE) &&
                isAbleTo(PermissionConstants.MARKETPLACE_CAMPAIGN_VIEW) &&
                isVBCRoute &&
                (isCustomerUser || isCoeusUser)
            );
        },
    };

    return [
        coeusOrgRoute,
        myOrgRoute,
        allOrgsRoute,
        customersRoute,
        tpaPortalsRoute,
        adminPortalsRoute,
        vbcDashboardRoute,
        vbcParticipantsRoute,
        vbcCoverageVerificationRoute,
        vbcHealthPlansRoute,
        vbcSubmissionsRoute,
        vbcReportsRoute,
        vbcMarketplaceRoute,
        notificationsRoute,
    ].filter((route: NavBarItem) => route.condition());
};
