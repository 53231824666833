import { FC, ReactElement } from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';
import { Color } from 'react-bootstrap/esm/types';

import { SVGIcon } from '../SVGIcon';

export interface BSBadgeProps extends BadgeProps {
    badgeText: string;
    className?: string;
    successBadge?: boolean;
    errorBadge?: boolean;
    fill?: boolean;
    inline?: boolean;
    icon?: string;
    rounded?: boolean;
    roundedFill?: boolean;
    showIcon?: boolean;
}

const getBadgeInfo = (status: string) => {
    switch (status) {
        // Success cases
        case 'sent':
        case 'accepted':
        case 'approved':
        case 'success':
        case 'aligned with criteria':
        case 'pass':
        case 'eligible':
        case 'approval not required':
        case 'active':
            return { bg: 'success', text: 'light', icon: 'ApprovedIcon' };

        // Warning cases
        case 'pending':
        case 'invite sent':
        case 'draft':
        case 'pending review':
            return { bg: 'warning', text: 'dark', icon: 'PendingIcon' };

        // Info cases
        case 'exempted':
        case 'not required':
        case 'clarification needed':
        case 'incomplete':
        case 'scheduled':
        case 'in progress':
        case 'requested contact':
            return { bg: 'info', text: 'dark', icon: 'InfoIcon' };

        // Danger cases
        case 'expired':
        case 'rejected':
        case 'invite expired':
        case 'error':
        case 'not aligned with criteria':
        case 'canceled':
        case 'not eligible':
        case 'declined':
        case 'inactive':
            return { bg: 'danger', text: 'light', icon: 'WarningIcon' };

        // Default case
        default:
            return { bg: 'primary', text: 'light', icon: '' };
    }
};

export const BSBadge: FC<BSBadgeProps> = ({
    className,
    badgeText,
    bg,
    successBadge,
    errorBadge,
    fill,
    inline,
    icon,
    rounded,
    roundedFill,
    showIcon = true,
    ...badgeProps
}): ReactElement => {
    const {
        bg: badgeBg,
        text: badgeTextType,
        icon: badgeIcon,
    } = getBadgeInfo(badgeText?.toLowerCase()) || {
        bg: 'primary',
        text: 'light',
        icon: icon || '',
    };

    const renderSuccessOrErrorBadge = (isSuccessBadge: boolean) => {
        return (
            <Badge
                bg={isSuccessBadge ? 'success' : 'danger'}
                className={`custom-badge ${className || ''} ${rounded && 'badge-rounded'}`}
                text="light"
                {...badgeProps}
            >
                {isSuccessBadge ? <>&#x2713;</> : <>X</>}
            </Badge>
        );
    };

    const generateBadgeClassNames = () => {
        const baseClasses = 'custom-badge';
        const fillClass = fill ? 'badge-fill' : '';
        const inlineClass = inline ? 'badge-inline' : '';
        const roundedLightClass = rounded ? 'badge-rounded-light' : '';
        const roundedFillClass = roundedFill ? 'badge-rounded' : '';

        return `${baseClasses} ${
            className || ''
        } ${fillClass} ${inlineClass} ${roundedLightClass} ${roundedFillClass}`;
    };

    const renderNormalBadge = () => {
        const badgeClasses = generateBadgeClassNames();

        const renderIcon = () =>
            showIcon && fill && badgeIcon && <SVGIcon icon={badgeIcon} className="svg-icon" />;

        const renderInlineDot = () => inline && <span className="dot"></span>;
        return (
            <Badge
                bg={bg || badgeBg}
                className={badgeClasses}
                text={badgeTextType as Color}
                {...badgeProps}
            >
                {renderIcon()}
                {renderInlineDot()}
                {badgeText}
            </Badge>
        );
    };

    if (successBadge) {
        return renderSuccessOrErrorBadge(true);
    }
    if (errorBadge) {
        return renderSuccessOrErrorBadge(false);
    }

    return renderNormalBadge();
};
